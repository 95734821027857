import { useState } from "react";
import axios from "axios";
import { APPLICATION_BASE_URL } from "src/common/constants";

export const getAuthToken = () => {
  // let localStore = JSON.parse(localStorage.getItem(APPLICATION_USER) as any) as any
  // let authorization = "Bearer " + localStore?.token;
  // return authorization;
  return ""
};

export const combineUrl = (endpoint: string) => {
  return APPLICATION_BASE_URL + endpoint
}

export default function useFetch(endPoint: string) {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getRequest = async (queryParams = {}): Promise<any> => {
    setLoading(true);
    try {
      const data = await axios.get(combineUrl(endPoint), { params: queryParams, headers: { authorization: getAuthToken() } })
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data]
    }
  };

  const getRequestById = async (id: string, payload = {}): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.get(combineUrl(`${endPoint}/${id}`), { params: payload, headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const postRequestById = async (id: string, payload = {}): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.post(combineUrl(`${endPoint}/${id}`), payload, { headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const postRequest = async (payload = {}, query = {}): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.post(combineUrl(endPoint), payload, { headers: { authorization: getAuthToken() }, params: query || {} });
      setData(data?.data);
      setLoading(false);
      return [data?.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const updateRequest = async (id: string, payload = {}): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.put(combineUrl(`${endPoint}/${id}`), payload, { headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const deleteRequest = async (id: string): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.delete(combineUrl(`${endPoint}/${id}`), { headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  return { data, loading, getRequest, postRequest, postRequestById, getRequestById, updateRequest, deleteRequest };
}
