import Footer from './components/Footer';
import Header from './components/Header'
import Homepage from './pages/Homepage';
import Landing from './pages/landing';

function App() {
  return (
    <div>
      {/* <Header />
      <Homepage />
      <Footer /> */}

      <Landing />
    </div>
  );
}

export default App;
