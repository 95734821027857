import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        style: {
          background: "#DCDCDC",
          color: "#000000",
          maxWidth: '100%',
          fontWeight: 'bold',
          fontSize: '14px'
        },
        // Default options for specific types
        success: {
          duration: 3000,
        },
        error: {
          duration: 3000
        }
      }}
    />
  </React.StrictMode>
);