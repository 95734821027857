import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import useFetch from 'src/hooks/useFetch'

const sponsorsLogo = (): string[] => {
  let list = [] as string[]
  let totalLogos = 14
  for (let i = 1; i <= totalLogos; i++) {
    list.push(`/img/landing/sponsors/${i}.svg`)
  }
  return list
}

export default function Landing() {

  const [email, setEmail] = useState("")
  const [startTicker, setStartTicker] = useState(false)
  const { postRequest: createEmailUser, loading: registerLoader } = useFetch('/api/v1/user/register')

  useEffect(() => {
    setTimeout(() => {
      setStartTicker(true)
    }, 600)
  }, [])

  const onClickNotify = async () => {
    try {
      if (!email) { return toast.error('Please enter email first') }
      const [response, error] = await createEmailUser({ email })
      if (error) {
        return toast.error(error.message)
      }
      toast.success(response.message)
      setEmail("")
    } catch (ex: any) {
      toast.error(ex.message)
    }
  }

  const keyPressed = (e: any) => {
    if (e.key === "Enter" && !registerLoader) {
      onClickNotify();
    }
  }

  return (
    <div className="relative overflow-auto h-screen w-full">
      <img src="/img/landing/landing-bg.png" alt="" className='fixed z-0 top-0 right-0 w-full h-full object-center object-cover opacity-80' />
      <div className='h-[126px] md:h-[16vh] min-w-[50px] w-[4%] py-[1%] md:py-[0%] pl-[14px] md:pl-[1.3%] absolute z-30 bg-[#2479DE] left-[7px] top-[38%] md:top-[45%] -translate-x-1/2 rounded-tr-2xl rounded-br-2xl md:rounded-tr-[1vw] md:rounded-br-[1vw]'>
        <div className='grid flex-col h-full w-full justify-center items-center'>
          <a href="https://x.com/SymbioteSync" target='_blank'><img src="/img/social/twitter.svg" alt="twitter" className='min-h-[14px]' /></a>
          <a href="https://t.me/+iWT-OhLgyOc4ODVl" target='_blank'><img src="/img/social/telegram.svg" alt="telegram" className='min-h-[14px]' /></a>
          <a href="mailto:contact@symbiote.gg" target='_blank'><img src="/img/social/mail.svg" alt="mail" className='min-h-[14px]' /></a>
        </div>
      </div>
      <div className='relative z-20 pb-[3%] flex flex-col w-full h-full text-center items-center'>
        <div className='relative z-20 h-[10%] md:h-[20%] w-full'>
          <div className='h-[36%] bg-gradient-to-r from-[#000000] to-[#2479DE] flex justify-center items-center'>
            <span className='text-sm md:text-[1vw] tracking-[5px]'>SUPPORTED BY</span>
          </div>
          <div className='h-[64%] w-full bg-[#000000b9] overflow-hidden flex'>
            <div className={`inline-flex px-4 justify-start items-center gap-6 h-full ${startTicker ? 'animate-marquee' : ''}`}>
              {sponsorsLogo().map((logo, index) => {
                return <img key={index} src={logo} alt="" className='w-fit max-w-[200px] h-[40%]' />
              })}
            </div>
            <div className={`inline-flex px-4 justify-start items-center gap-6 h-full ${startTicker ? 'animate-marquee' : ''}`}>
              {sponsorsLogo().map((logo, index) => {
                return <img key={index} src={logo} alt="" className='w-fit max-w-[200px] h-[40%]' />
              })}
            </div>
          </div>
        </div>
        <div className='h-[45%] md:h-[55%] flex justify-center items-center pt-[2%]'>
          <img src="/img/header/header-logo.png" alt="" className='min-w-[260px] min-h-[280px] h-[76%] md:h-full m-auto' />
        </div>
        <div className='flex justify-center items-center gap-2 w-[100%] h-[10%] mt-[2%]'>
          <div className='hidden md:block h-[10%] w-[20%] bg-gradient-to-r from-[#000E10] to-[#FF8B10]' />
          <h2 className='text-2xl md:text-[1.8vw] w-[100%] md:w-[40%] font-semibold px-2'>THE ULTIMATE WEB3 STARTUPS SHOWDOWN</h2>
          <div className='hidden md:block h-[10%] w-[20%] bg-gradient-to-r from-[#FF8B10] to-[#000E10]' />
          <div />
        </div>
        <p className='text-center text-sm md:text-[1.3vw] mt-[2%] h-[10%] md:h-[5%] px-2'>
          A month long ultimate pitching competition for Web3 Startups.
          {/* <br/> */}
          {/* <span className='block font-semibold mt-[0.8%]'>A Prize Pool worth $1M USD to be won.</span> */}
        </p>

        <div className='flex items-center justify-center mt-[2%] w-[90%] md:w-[36%] min-h-[40px] h-[10%] max-h-[50px]'>
          <input
            type="text"
            className='block bg-black border border-[#2479DE] outline-none p-2 md:py-2 md:px-5 rounded-xl w-[66%] h-full text-xs md:text-[1vw]'
            placeholder='Enter your Email ID'
            onKeyDown={keyPressed}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button
            className='ml-[3%] w-[34%] h-full border border-[#FF8710] bg-black rounded-xl text-xs md:text-[1vw] font-semibold py-1'
            onClick={onClickNotify}
            disabled={registerLoader}
          >
            {registerLoader ? "Please wait..." : "Notify on launch"}
          </button>
        </div>
        <div className='flex flex-col md:flex-row justify-center items-center gap-4 md:gap-20 text-sm md:text-[1vw] w-full h-[10%] md:h-[5%] mt-[3%] md:mt-[2%]'>
          <a href="https://forms.gle/EwNq35T8TGBC6qFt8" target='_blank' className='underline'>I want to Participate</a>
          <a href="https://forms.gle/gHisnQtggLF9PWBMA" target='_blank' className='underline'>I want to Partner / Sponsor</a>
        </div>
      </div>
    </div>
  )
}
